<template>
    <div class="wrapper">
        <div class="top_bg"></div>
        <div class="news_con">
            <div class="map-btn">
                <a href="/" class="home" onclick="PTTSendClick('btn','home','首页');">首页</a>
                <span style="color: white;">></span>
                <a href="#/news_list/" class="home" onclick="PTTSendClick('btn','news','新闻资讯');">新闻资讯</a>
                <span style="color: white;">>详情页</span>
            </div>
            <a href="javascript:history.back(-1);" class="back" onclick="PTTSendClick('btn','back','返回');">返回</a>

            <div class="news_container">
                <div class="newscon_top">
                    <p class="news-ti">{{NewsDetail.title}}</p>
                    <div class="news-desc">
                        &nbsp;&nbsp;&nbsp;时间：
                        <span>{{NewsDetail.dateStr}}</span>
                    </div>
                </div>

                <div class="news_center">
                    <div class="document" v-html="NewsDetail.htmlContent">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
  
<script>
import { request } from '../../request/request';

export default {
    name: "news_Detail",
    data() {
        return {
            NewsDetail: "",
        }
    },
    methods: {



    },
    mounted() {
        window.scrollTo(0, 0)

        const { _id } = this.$route.query
        if (!_id) return
        console.log("new_detail:",)
        request({
            url: "/web/getNewsById?_id=" + _id,
            methods: "get",
        }).then(res => {
            if (res.data.code === 200) {
                const { news } = res.data.data
                this.NewsDetail = news
            }
        })
    },
}
</script>
  
<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none
}


.wrapper {
    position: relative;
    margin: 0 auto;
    min-width: 1900px;
    max-width: 1920px;
    overflow: hidden;
}

.top_bg {
    background: url("../../assets/images/mpp/news_top_bg.png") no-repeat center;
    height: 717px;
    width: 100%;
}

.news_con {
    background: url("../../assets/images/mpp/news_con_bg.png");
    //height: 1200px;
    width: 100%;
    position: relative;
}

.map-btn {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -600px;
    color: #734f1b;
    font-size: 16px;
}

.back {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: 570px;
    color: white;
    font-size: 16px;
}

.news_container {
    width: 1200px;
    margin: 0 auto;
}

.newscon_top {
    padding-top: 50px;
    border-bottom: 1px solid #e8cda5;

    .news-ti {
        color: white;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
    }
}

.news-desc {
    text-align: center;
    color: white;
    font-size: 20px;
    margin: 40px 0;
}

.news_center {
    margin-top: 20px;
    font-size: 20px;
    color: #503f2d;
    line-height: 60px;
    // overflow-y: auto;
    // height: 900px;
}


.news_center::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #f3c258;
}

.news_center::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 5px;
}

.news_center::-webkit-scrollbar {
    width: 5px;
    height: 1px;
    border-radius: 5px;
}

.home {
    color: white;
}

.document {
    color: white;
    text-align: center;
}
</style>