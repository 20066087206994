<template>
  <div class="features">
    <div class="title"></div>
    <div class="bg2">
    </div>
    <div class="swiper-container">
      <swiper :modules="modules" :speed="1200" :slides-per-view="1" :space-between="0"
        :autoplay="{ delay: 3000, disableOnInteraction: false }" effect="fade" :pagination="{clickable: true}"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }" @slide-change="slideChange">
        <!-- <swiper-slide>
          <div class="video-wrapper">
            <video id="video1" name="video" src="../../assets/video/video1.mp4" class="video"
              v-on:mousemove="mousemove"></video>
            <a href="javascript:;" id="j3328abxwf2" class="vid-btn" @click="openPopVideo(1)"></a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="video-wrapper">
            <video id="video2" name="video" src="../../assets/video/video1.mp4" class="video"></video>
            <a href="javascript:;" id="j3328abxwf2" class="vid-btn" @click="openPopVideo(2)"></a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="video-wrapper">
            <video id="video3" name="video" src="../../assets/video/video1.mp4" class="video"></video>
            <a href="javascript:;" id="j3328abxwf2" class="vid-btn" @click="openPopVideo(3)"></a>

          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="video-wrapper">
            <video id="video4" name="video" src="../../assets/video/video1.mp4" class="video"></video>
            <a href="javascript:;" id="j3328abxwf2" class="vid-btn" @click="openPopVideo(4)"></a>
          </div>
        </swiper-slide> -->


        <swiper-slide :key="index" v-for="(url, index) in banners">
          <div class="video-wrapper">
            <video :id="'video_'+(index)" name="video" :src="url" class="video" @click="onPause(index)"
              v-on:ended="onPlayEnd(index)"></video>
            <a href="javascript:;" :id="'playIcon_'+(index)" class="vid-btn" @click="onPlay(index)" :hidden="false"></a>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="cat"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
export default {
  name: "home_Features",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      banners: [
        require("../../assets/video/video1.mp4"),
        require("../../assets/video/video2.mp4"),
        require("../../assets/video/video3.mp4"),
        require("../../assets/video/video4.mp4"),
      ],
      modules: [Pagination, Navigation],
    }
  },
  methods: {
    slideChange() {
      let videos = document.getElementsByName('video')
      for (var v of videos) {
        if (!v.paused) {
          v.pause()
          let id = v['id'].split('_')[1]
          let playBtn = document.getElementById('playIcon_' + id)
          playBtn.hidden = false
        }
      }
    },

    onPause(index) {
      let vid = document.getElementById('video_' + index)
      if (!vid.paused) {
        vid.pause()
        let playBtn = document.getElementById('playIcon_' + index)
        playBtn.hidden = false
      }
    },

    onPlay(id) {
      let playBtn = document.getElementById('playIcon_' + id)
      playBtn.hidden = true

      let vid = document.getElementById('video_' + id)
      vid.play()
    },

    onPlayEnd(id) {
      let playBtn = document.getElementById('playIcon_' + id)
      playBtn.hidden = false
    }

  },

}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none
}

.features {
  height: 975px;
  background: url("../../assets/images/mpp/p3_bg.png") center center no-repeat;
  overflow: hidden;

  .title {
    background: url("../../assets/images/mpp/p3_title_hk.png") center center no-repeat;
    position: relative;
    top: 50px;
    width: 579px;
    height: 96px;
    left: 35%;
  }

  .bg2 {
    background: url("../../assets/images/mpp/p3_bg2.png") no-repeat;
    width: 1920px;
    height: 675px;
    margin: 100px 0px 0px 280px;

  }

  .cat {
    background: url("../../assets/images/mpp/p3_cat.png");
    display: block;
    width: 370px;
    height: 104px;
    position: absolute;
    top: 2160px;
    margin-left: 350px;
  }
}


.swiper-container {
  position: relative;
  top: -620px;
  width: 965px;
  height: 555px;
  margin-left: 380px;
}

.swiper:deep() {
  .swiper-slide {
    height: 555px;
    width: 963px;
    line-height: 300px;
    font-size: 30px;
    text-align: center;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 100px;

    .swiper-pagination-bullet {
      width: 32px;
      height: 32px;
      background: url("../../assets/images/mpp/p2_bullet.png") no-repeat;
    }

    .swiper-pagination-bullet-active {
      width: 32px;
      height: 32px;
      background: url("../../assets/images/mpp/p2_bullet_active.png") no-repeat;
    }
  }
}




.swiper-button-prev {
  background: url("../../assets/images/mpp/p3_left.png");
  position: absolute;
  height: 98px;
  width: 67px;
  left: -300px;
  top: 200px;
}

.swiper-button-next {
  background: url("../../assets/images/mpp/p3_right.png");
  position: absolute;
  height: 98px;
  width: 67px;
  left: 1350px;
  top: 200px
}

.swiper-button-prev,
.swiper-button-next {
  &::after {
    font-size: 0px;
    color: white;
  }
}

.swiper-button-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.video-wrapper {
  .video {
    height: 100%;
    border-radius: 20px;
  }
}

.vid-btn {
  background: url(//game.gtimg.cn/images/htrj/web202007/index/vid-btn.png) no-repeat center;
  width: 111px;
  height: 110px;
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
}
</style>