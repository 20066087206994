<template>
  <div class="contact">
    <div class="title"></div>
    <div class="qrcode">
      <!-- <div class="code1">
        <img class="qrcode_kf" src="../../assets/images/mpp/qrcode_kf.png" alt="taptap">
      </div>
      <div class="code2">
        <img class="qrcode_wxgzh" src="../../assets/images/mpp/qrcode_wxgzh.jpg" alt="taptap">
      </div> -->
    </div>
    <div class="third">
      <div class="girl1"> </div>
      <img class="discord" src="../../assets/images/mpp/discord.png" alt="discord" @click="clickDiscord">
      <div class="girl2"> </div>
      <img class="facebook" src="../../assets/images/mpp/facebook.png" alt="facebook" @click="clickFacebook">
      <div class="girl3"> </div>
      <img class="ins" src="../../assets/images/mpp/ins.png" alt="instagram" @click="clickIns">
    </div>
  </div>
</template>

<script>
export default {
  name: "Home_Contact",
  data() {
    return {
    };
  },
  methods: {
    clickDiscord() {
      window.open("https://discord.gg/YDmEnHVr", '_blank')
    },
    clickFacebook	() {
      window.open("https://www.facebook.com/MPPapartment", '_blank')
    },
    clickIns() {
      window.open("https://www.instagram.com/mppdxfgy/", '_blank')
    }
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none
}

.contact {
  height: 1570px;
  background: url("../../assets/images/mpp/p4_bg.jpg") center center no-repeat;
  overflow: hidden;

  .title {
    background: url("../../assets/images/mpp/p4_title_hk.png") center center no-repeat;
    position: relative;
    top: 50px;
    width: 579px;
    height: 96px;
    left: 35%;
  }

  .qrcode {
    margin-top: 100px;
    width: 800px;
    height: 100px;
    display: flex;

    .code1 {
      background: url("../../assets/images/mpp/icon_qrcode.png") center center no-repeat;
      width: 367px;
      height: 417px;
      position: relative;
      left: 400px;
    }

    .qrcode_kf {
      width: 56%;
      top: 28%;
      left: 13%;
      position: relative;
    }

    .qrcode_wxgzh {
      width: 56%;
      top: 28%;
      left: 13%;
      position: relative;
    }

    .code2 {
      background: url("../../assets/images/mpp/icon_qrcode.png") center center no-repeat;
      height: 417px;
      width: 367px;
      position: relative;
      left: 800px;
    }
  }

  .third {
    margin-top: 120px;
    display: flex;

    .girl1 {
      background: url("../../assets/images/mpp/p4_girl1.png") no-repeat;
      position: relative;
      width: 163px;
      height: 246px;
      left: 15%;
    }

    .discord {
      position: relative;
      left: 15%;
    }

    .girl2 {
      background: url("../../assets/images/mpp/p4_girl2.png");
      position: relative;
      width: 163px;
      height: 246px;
      left: 20%;
    }

    .facebook {
      position: relative;
      left: 20%;
    }

    .girl3 {
      background: url("../../assets/images/mpp/p4_girl3.png");
      position: relative;
      width: 163px;
      height: 246px;
      left: 25%;
    }


    .ins {
      position: relative;
      left: 25%;
    }
  }
}
</style>