<template>
    <div class="wrapper">
        <div class="top_bg"></div>
        <div class="news_con">
            <div class="map-btn">
                <a href="/" class="home">首页</a>
                <span style="color: white;">></span>
                <a class="home">新闻资讯</a>
            </div>

            <div class="news_nav2">
                <a href="javascript:;" v-for="(tab, index) in navBar" :key="index" :class="{ on: newsSelect == index }"
                    @click="SelectNav(tab, index)">{{tab}}</a>
            </div>

            <div class="news_content">
                <div class="news_ul on">
                    <ul id="news-con" style="">
                        <li v-for="(item, index) in selectList" :key="index">
                            <a class="li_con" @click="JumpToDetail(item._id)">
                                <em>♥</em>
                                <div class="li_licon">{{item.title}}</div>
                                <span class="li_time">{{item.time}}</span>
                            </a>
                        </li>
                    </ul>
                    <!-- <div class="page">
                        <div name="laypage1.3" class="laypage_main laypageskin_default" id="laypage_6"><a
                                href="javascript:;" class="laypage_next" data-page="2">下一页</a></div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

</template>
  
<script>
import { request, changeTabNameBack, allTabls } from '../../request/request';

export default {
    name: "news_List",
    data() {
        return {
            newsContent: {
                htmlContent: ''
            },
            navBar: allTabls,
            newsSelect: 0,
            selectList: [],
            allNews: []
        }
    },
    methods: {
        SelectNav(tab, index) {
            this.newsSelect = index
            tab = changeTabNameBack(tab)
            this.selectList = this.allNews[tab]
        },
        JumpToDetail(_id) {
            if (!_id) return
            this.$router.push({ path: '/news_detail', query: { _id } })
        }

    },
    mounted() {
        window.scrollTo(0, 0)
        request({
            url: "/gw/getNews?platform=PC&page=list",
            methods: "get",
        }).then(res => {
            if (res.data.code === 200) {
                const { list } = res.data.data
                this.allNews = list
                this.selectList = this.allNews['全部']
            }

        })
    },
}
</script>
  
<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none
}


.wrapper {
    position: relative;
    margin: 0 auto;
    min-width: 1900px;
    max-width: 1920px;
    overflow: hidden;
}

.top_bg {
    background: url("../../assets/images/mpp/news_top_bg.png") no-repeat center;
    height: 717px;
    width: 100%;
}

.news_con {
    background: url("../../assets/images/mpp/news_con_bg.png") no-repeat center;
    height: 1200px;
    width: 100%;
    position: relative;
}

.map-btn {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -600px;
    color: #734f1b;
    font-size: 16px;
}

.home {
    color: white;
}

.news_nav2 {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid white;

    a {
        display: inline-block;
        width: 110px;
        height: 39px;
        color: white;
        text-align: center;
        line-height: 39px;
        font-size: 24px;
    }

    a.on {
        background: url("../../assets/images/mpp/news_list_btn.png") no-repeat;
        color: #fff;
    }
}


.news_ul {
    width: 1200px;
    margin: 0 auto;
    display: none;
}

.news_ul.on {
    display: block;
}

.li_con {
    display: block;
    overflow: hidden;
    padding-left: 10px;
    position: relative;
    height: 120px;
    line-height: 120px;
    border-bottom: 1px solid #e8cda5;
    color: white;
}

.li_con em {
    position: absolute;
    top: 0;
    left: 50px;
    width: 10px;
    height: 10px;
    color: white;
}

.li_licon {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 1030px;
    float: left;
    margin-left: 80px;
    font-size: 18px;
}

.li_time {
    display: block;
    float: left;
    color: white;
}

.li_con:hover,
.li_con:hover em,
.li_con:hover .li_time {
    color: #6cd8d5;
}

.li_con:hover {
    background: #4f90c7;
}
</style>