<template>
  <div class="Nav">
    <div class="Nav-Wrap">
      <img src="../assets/images/mpp/logo_hw.png" class="NavLogo">
      <div class="NavItems" style="margin-right: 55px" v-for="(Show, index) in NavLeftItem.Top" :key=index
        :class="{NavItems_Select:NavSelect==index}" @click="NavLink(index)">
        <div class="NavLeftItem-Top">{{ Show }}</div>
        <div class="NavItem-Bottom">{{ NavLeftItem.Bottom[index] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'compNavigation',
  data() {
    return {
      NavLeftItem: {
        Top: ['首頁', '資訊活動', '遊戲特色', '關於我們',],
        Bottom: ['HOME', 'NEWS', 'FEATURES', "CONTACT"]
      },
      NavSelect: 0,
    }
  },
  computed: {

  },
  methods: {
    NavLink(index) {
      this.NavSelect = index
      let path = this.$route.path
      if (path !== '/') {
        this.$router.push('/')
      }
      window.scroll({
        top: index * 1000,
        behavior: 'smooth'
      });

    },

  }
}
</script>

<style>
.Nav {
  position: relative;
  /* top: 0;
  width: 100%; */
  height: 83px;
  background-color: #4CA5A5;
  box-shadow: 0 2px 10px 0 rgba(1, 1, 1, 0.3);
  z-index: 99999;
  /* min-width: 1240px; */
  margin: 0 auto;
  min-width: 1900px;
  max-width: 1920px;
}

.Nav-Wrap {
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  transform: translate(-50%, -50%);
  padding-left: 55px;
}

.NavItems {
  position: relative;
  text-align: center;
  display: block;
  line-height: 1.65;
  color: white;
  font-weight: bold;
}

.NavItems:hover {
  color: #FFED56;
  cursor: pointer;
}

.NavItems_Select {
  color: #FFED56;
}

.NavItem-Bottom {
  font-size: 12px;
  opacity: 0.3;
  /* color: white; */
}

.NavLeftItem-Top {
  /* color: white; */
  font-size: 19px;
  font-style: normal;
}

.NavRightItem-Top {
  font-size: 14px;
}

.NavLogo {
  position: absolute;
  left: -100px;
}
</style>