<template>
  <div class="HOME_Header">
    <!-- <img src="../../assets/images/mpp/age.png" class="age" @click="openPopAge" /> -->
    <img src="https://pic.yaolinggame.com/gwSource/web/logo.png" class="logo" @click="JumpYl" />
    <div class="download_">
      <img src="../../assets/images/mpp/download.png" />
      <div class="download_ad" @click="JumpDownloadAd"></div>
      <div class="download_ios" @click="JumpDownloadIos"></div>
    </div>

    <!-- <div class="downLoad"> -->
      <!-- <img class="qrBoard" src="../../assets/images/mpp/mini.jpg" />
      <div class="btn_android" @click="DownLoadBtn(1)">
        <img src="../../assets/images/mpp/btn_yy.png" />
      </div> -->
      <!-- <div class="btn_ios" @click="DownLoadBtn(2)">
        <img src="../../assets/images/mpp/btn_ios.png" />
      </div> -->
    <!-- </div> -->
    <!-- <img src="../../assets/images/mpp/p1_zi.png" class="zi" /> -->
  </div>


</template>
<script>
export default {
  name: "HOME_Header",
  data() {
    return {
    };
  },
  computed: {

  },
  watch: {
  },
  created() {
    // this.axios
    //   .get(
    //     "https://www.bh3.com/content/bh3Cn/getContentList?pageSize=6&pageNum=1&channelId=168"
    //   )
    //   .then((res) => {
    //     for (var i = 0; i < res.data.data.list.length; i++) {
    //       this.banners.push(res.data.data.list[i]);
    //     }
    //   });

    // 获取首页扫码下载右边的pv封面视频，还有视频本体
    // this.axios
    //   .get(
    //     "https://www.bh3.com/content/bh3Cn/getContentList?pageSize=6&pageNum=1&channelId=170"
    //   )
    //   .then((res) => {
    //     //获取首页pv的封面视频路径
    //     this.pvCover = res.data.data.list[0].ext[0].value[0].url;
    //     this.$store.state.homeVideo = res.data.data.list[0].ext[1].value[0].url;
    //   });
  },
  methods: {
    JumpDownloadIos() {
      // window.open("https://apps.apple.com/cn/app/%E7%8C%AB%E5%A9%86%E5%A9%86%E7%9A%84%E5%B9%B8%E7%A6%8F%E5%85%AC%E5%AF%93/id1605891373", '_blank')
    },
    JumpDownloadAd() {
      window.open("https://play.google.com/store/apps/details?id=com.shining.landlord", '_blank')
    },
    JumpYl() {
      // window.open("http://yaolinggame.com/", '_blank')
    },

    openPopAge() {
      let overlay = document.getElementById('_overlay_')
      overlay.style.display = "block"
      let popSty = document.getElementById('pop_age')
      popSty.style.display = "block";
    },
    DownLoadBtn(index) {
      window.scroll(0,0)
      if (index == 1) {
        let overlay = document.getElementById('_overlay_')
        overlay.style.display = "block"
        let popSty = document.getElementById('pop_wx')
        popSty.style.display = "block";

        // window.open(
        //   "https://api-takumi.mihoyo.com/event/download_porter/link/bh3_cn/bh3/pc_official"
        // );
      } else {
        let overlay = document.getElementById('_overlay_')
        overlay.style.display = "block"
        let popSty = document.getElementById('pop_game')
        popSty.style.display = "block";
        // window.open(
        //   "https://api-takumi.mihoyo.com/event/download_porter/link/bh3_cn/bh3/android_official"
        // );
      }
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none;
}

.HOME_Header {
  position: relative;
  height: 1080px;
  overflow: hidden;
  background: url("../../assets/images/mpp/p1_bg_hk.png") no-repeat;

  .video {
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo {
    position: absolute;
    top: 10%;
    left: 41px;
  }
  .download_ {
    position: fixed;
    z-index: 99999;
    bottom: 200px;
    left: 50px;
  }

  .download_ios {
    // background-color: blue;
    position: absolute;
    top: 20px;
    left: 220px;
    width: 200px;
    height: 80px;
  }

  .download_ad {
    // background-color: red;
    position: absolute;
    top: 120px;
    left: 220px;
    width: 200px;
    height: 80px;
  }

  .age {
    position: absolute;
    top: 10%;
    left: 41px;
  }

  .downLoad {
    background: url("../../assets/images/mpp/qrcode_board.png") no-repeat;
    position: absolute;
    width: 461px;
    height: 187px;
    left: 689px;
    top: 839px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 10px 0 rgba(1, 1, 1, 0.3);
    border-radius: 5px;
    padding: 5px;

  }

  .qrBoard {
    position: relative;
    top: 5px;
    width: 165px;
  }

  .btn_android {
    position: absolute;
    left: 184px;
    top: 50px;
  }

  .btn_ios {
    position: absolute;
    left: 184px;
    top: 100px;
    border-radius: 5px;
  }

  .zi {
    position: absolute;
    top: 110px;
    left: 1100px;
  }

}
</style>