import axios from 'axios'

export function request(config) {
    let baseURL
    if (process.env.NODE_ENV == "development"){
        baseURL = 'http://127.0.0.1:19000'
    } else {
        baseURL = window.location.origin + '/web/api'
    }
    const instance = axios.create({
        //baseURL: 'http://192.168.0.122:19010'
        //baseURL: 'https://t-wegame.legendtrip.com/web/api'
        // baseURL: 'https://hkgw.yaolinggame.com/web/api'
        
        
        baseURL

    })
    return instance(config)
}

const tabMap = [["活动","活動"],["新闻","問答"]]
export const allTabls = ["全部", "版本","問答", "活動", "公告"]

export function changeTabName(tab) {
    for(const val of tabMap){
        let [k,v] = val
        if(k == tab){
            return v
        }
    }
    return tab
}

export function changeTabNameBack(tab) {
    for(const val of tabMap){
        let [k,v] = val
        if(v == tab){
            return k
        }
    }
    return tab
}